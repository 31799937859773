// SeriesInfoCard.js
import React from "react";
import { Card, CardContent } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { Global, stSubPage } from "../../GlobalStyles";
import { Button } from "@mui/material";

const seriesStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center", // 세로 중앙 정렬
  marginLeft: "20px",
  marginRight: "20px",
  marginTop: "10px",
  fontSize: "17px",
  color: Global.greyHover,
};

const sortButtonSx = {
  fontFamily: "Pretendard, sans-serif",
  color: "white",
  border: `1px solid ${Global.greyHover}`,
  padding: "0px 10px", // 좌우 패딩 추가
  fontSize: "14px",
  "&:hover": {
    color: "white",
    backgroundColor: Global.greyHover,
  },
};
const containerStyle = {
  display: "block",
  marginTop: "-10px",
  marginLeft: "20px",
  marginRight: "20px",
  marginBottom: "50px",
  borderTop: `1px solid ${stSubPage.border}`,
};

const cardStyle = {
  marginBottom: "10px",
  color: "white",
  fontSize: "15px",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  padding: "0px 0px",
  transition: "background-color 0.3s",
  position: "relative",
  cursor: "pointer",
  backgroundColor: "#333746",
};

const cardContentStyle = {
  padding: "10px",
};

const SeriesInfoCard = ({ summary, curEpisode, isReversed, showDate, handleSortClick }) => {
  const sortedSeriesInfo = isReversed ? [...summary.SeriesInfo].reverse() : summary.SeriesInfo;
  const navigate = useNavigate();

  const handleCardClick = (clickedEpisode) => {
    // console.log(clickedEpisode);
    navigate("/content", { state: { summary, episode: clickedEpisode } });
  };

  //console.log("sortedSeriesInfo 개수:", sortedSeriesInfo.length);

  return (
    <div style={containerStyle}>
      <div style={{ ...seriesStyle, marginBottom: "15px" }}>
        연재 총{sortedSeriesInfo.length}화
        <Button sx={sortButtonSx} onClick={handleSortClick}>
          정렬
        </Button>
      </div>
      {sortedSeriesInfo.map((episode, index) => {
        const isCurrentEpisode = curEpisode
          ? episode.EpisodeTitle === curEpisode.EpisodeTitle
          : false;
        const backgroundColor = isCurrentEpisode ? "black" : "#333746";
        const color = isCurrentEpisode ? "#fc9c34" : "white";

        return (
          <Card
            key={index}
            variant="outlined"
            style={{
              ...cardStyle,
              color,
              backgroundColor,
            }}
            onClick={() => handleCardClick(episode)}
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor = "#355";
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor = backgroundColor;
            }}
          >
            <CardContent style={cardContentStyle}>
              {episode.EpisodeTitle}
              {showDate && (
                <div style={{ color: "#6c757d", fontSize: "12px", marginTop: "7px" }}>
                  <CalendarMonthIcon fontSize="12px" style={{ margin: "0 3px -1.5px 0" }} />
                  {episode.Date}
                </div>
              )}
            </CardContent>
          </Card>
        );
      })}
    </div>
  );
};

export default SeriesInfoCard;
