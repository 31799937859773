import React from "react";
import styled from "styled-components";

const PopularSearchesContainer = styled.div`
  .popular-searches-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 5px;
  }

  .popular-search {
    border: 1px solid transparent;
    padding: 10px;
    text-align: left;
    position: relative;
  }

  .bold-number {
    font-weight: bold;
    position: absolute;
    text-align: left;
  }
  .search-text {
    margin-left: 30px;
  }
  .number-1 {
    color: orange;
  }
  .number-2 {
    color: orange;
  }
  .number-3 {
    color: orange;
  }

  .h4-title {
    margin-top: 50px;
    color: white;
  }
`;

const popularSearches = [
  "나 혼자만 레벨업",
  "외모지상주의",
  "99강화나무몽둥이",
  "화산귀환",
  "퀘스트 지상주의",
  "데뷔 못 하면 죽는 병",
  "이세계아이돌",
  "김부장",
  "나혼자 레벨업",
  "비뢰도",
];

const PopularSearches = () => {
  return (
    <PopularSearchesContainer>
      <h4 className="h4-title">인기 검색어</h4>
      <div className="popular-searches-grid">
        {popularSearches.map((search, index) => (
          <div key={index} className="popular-search">
            <span className={`bold-number number-${index + 1}`}>{index + 1}</span>
            <span className="search-text">{search}</span>
          </div>
        ))}
      </div>
    </PopularSearchesContainer>
  );
};

export default PopularSearches;
