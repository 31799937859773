import React from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";

// 아이콘 스타일을 추가
const iconArrowBackStyle = {
  width: "25px",
  position: "fixed", // 고정 위치로 설정
  top: "46%",
  left: "3%", // 가로 세로 중앙에 배치
  zIndex: 1,
  color: "white",
  backgroundColor: "#1b1e29",
  display: "inline-block",
  padding: "6px",
  borderRadius: "5px",
  // transform: 'translate(-50%, -50%)', // 가로 세로 중앙으로 이동
};

const iconArrowForwardStyle = {
  width: "25px",
  position: "fixed", // 고정 위치로 설정
  top: "46%",
  right: "3%", // 가로 세로 중앙에 배치
  zIndex: 1,
  color: "white",
  backgroundColor: "#1b1e29",
  display: "inline-block",
  padding: "6px",
  borderRadius: "5px",
  // transform: 'translate(-50%, -50%)', // 가로 세로 중앙으로 이동
};

const iconArrowUpStyle = {
  width: "40px",
  position: "fixed", // 고정 위치로 설정
  top: "72%",
  right: "3%", // 가로 세로 중앙에 배치
  zIndex: 1,
  color: "white",
  backgroundColor: "#343a40",
  display: "inline-block",
  padding: "6px 2px 6px 2px",
  borderRadius: "5px",
  // transform: 'translate(-50%, -50%)', // 가로 세로 중앙으로 이동
};

const iconArrowDownStyle = {
  width: "40px",
  position: "fixed", // 고정 위치로 설정
  top: "78%",
  right: "3%", // 가로 세로 중앙에 배치
  zIndex: 1,
  color: "white",
  backgroundColor: "#343a40",
  display: "inline-block",
  padding: "6px 2px 6px 2px",
  borderRadius: "5px",
  // transform: 'translate(-50%, -50%)', // 가로 세로 중앙으로 이동
};

const buttonStyle = {
  color: "#ffdb34",
  backgroundColor: "rgba(43,43,46,0.95)",
  position: "fixed",
  bottom: "50px", // 원하는 위치로 조정
  height: "60px",
  fontSize: "16px",
  left: 0,
  right: 0,
  width: "100%", // 윈도우 가로 너비에 맞게 설정
  zIndex: 1, // 다른 요소 위에 표시되도록 설정
};

const ChapterCenterNav = ({ episode, summary, onArrowUpClick, onArrowDownClick }) => {
  const handleArrowUpClick = (e) => {
    e.stopPropagation(); // 이벤트 전파 중지
    onArrowUpClick(e); // 부모 컴포넌트로 이벤트 전달
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleArrowDownClick = (e) => {
    e.stopPropagation(); // 이벤트 전파 중지
    onArrowDownClick(e); // 부모 컴포넌트로 이벤트 전달
    window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
  };

  const episodeTitle = episode?.EpisodeTitle;

  // episodeTitle이 정의되어 있으면서 match 가능한 경우에만 수행
  const episodeNumber =
    episodeTitle && episodeTitle.match(/(\d+) -/)
      ? parseInt(episodeTitle.match(/(\d+) -/)[1])
      : null;
  const nextEpisodeNumber = (episodeNumber + 1).toString(); // 1을 더한 값을 생성
  const prevEpisodeNumber = (episodeNumber - 1).toString(); // 1을 뺀 값을 생성

  console.log("nextEpisodeNumber:", nextEpisodeNumber);

  // summary.SeriesInfo 배열에서 다음 에피소드 번호를 가진 요소를 찾음
  const nextEpisode = summary.SeriesInfo.find((item) =>
    item.EpisodeTitle.includes(nextEpisodeNumber)
  );

  // summary.SeriesInfo 배열에서 다음 에피소드 번호를 가진 요소를 찾음
  const prevEpisode = summary.SeriesInfo.find((item) =>
    item.EpisodeTitle.includes(prevEpisodeNumber)
  );

  console.log("nextEpisode:", nextEpisode);
  console.log("prevEpisode:", prevEpisode);

  const navigate = useNavigate();
  const handleForwardClick = () => {
    // console.log(summary);
    // console.log(episode);
    navigate("/content", { state: { summary, episode: nextEpisode } });
  };

  const handleBackClick = () => {
    // console.log(summary);
    // console.log(episode);
    navigate("/content", { state: { summary, episode: prevEpisode } });
  };

  return (
    <>
      {prevEpisode && (
        <ArrowBackIosIcon
          fontSize="medium"
          style={{ ...iconArrowBackStyle }}
          onClick={() => handleBackClick()}
        />
      )}
      {nextEpisode && (
        <ArrowForwardIosIcon
          fontSize="medium"
          style={{ ...iconArrowForwardStyle }}
          onClick={() => handleForwardClick()}
        />
      )}
      <ArrowUpwardIcon
        fontSize="medium"
        style={{ ...iconArrowUpStyle }}
        onClick={handleArrowUpClick}
      />
      <ArrowDownwardIcon
        fontSize="medium"
        style={{ ...iconArrowDownStyle }}
        onClick={handleArrowDownClick}
      />
      {nextEpisode && (
        <Button style={{ ...buttonStyle }} onClick={() => handleForwardClick()}>
          다음화
          <KeyboardDoubleArrowRightIcon fontSize="medium" />
        </Button>
      )}
    </>
  );
};

export default ChapterCenterNav;
