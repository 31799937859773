import React, { useState } from "react";
import { GlobalStyle, MaterialGlobalStyle } from "../../GlobalStyles";
import ShortCutPage from "./ShortCutPage";
import BestOfDayNav from "../Navi/BestOfDayNav";
import PopularGenreButton from "../Button/PopularGenreButton";

const PopularPage = () => {
  const [day, setDay] = useState(0);

  const handleDayBtnClick = (newValue) => {
    setDay(newValue);
  };

  return (
    <MaterialGlobalStyle>
      <GlobalStyle />
      <BestOfDayNav />
      <PopularGenreButton />
      <ShortCutPage type="popular" selectedDay="전체"/>
    </MaterialGlobalStyle>
  );
};

export default PopularPage;
