import React, { useState } from "react";
import { useTheme } from "@mui/system";
import { AppBar, Toolbar, useMediaQuery, Button } from "@mui/material";
import { GlobalStyle, MaterialGlobalStyle, MUITheme } from "../../GlobalStyles";
import SerialPage from "./SerialPage";
import CompletedPage from "./CompletedPage";
import PopularPage from "./PopularPage";

const appBarBGColor = "#0B0B1C";
const redDefault = "#F83D3D";
const redHover = "#F83D3D";

const WebtooNavPage = () => {
  const theme = useTheme(MUITheme);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [selectedTab, setSelectedTab] = useState(0);

  const handleButtonClick = (newValue) => {
    setSelectedTab(newValue);
  };

  const buttonSx = {
    width: "500px",
    fontFamily: "Pretendard, sans-serif",
    color: "white",
    fontSize: "15px",
    "&:hover": {
      color: "white",
      backgroundColor: redHover,
    },
  };

  const getButtonStyle = (index) => ({
    ...buttonSx,
    backgroundColor: selectedTab === index ? redDefault : undefined,
  });

  return (
    <MaterialGlobalStyle>
      <GlobalStyle />
      <AppBar
        position="static"
        sx={{ backgroundColor: appBarBGColor, maxWidth: "1170px", margin: "10px auto 0" }}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Button onClick={() => handleButtonClick(0)} sx={getButtonStyle(0)}>
            연재
          </Button>
          <Button onClick={() => handleButtonClick(1)} sx={getButtonStyle(1)}>
            완결
          </Button>
          <Button onClick={() => handleButtonClick(2)} sx={getButtonStyle(2)}>
            인기
          </Button>
        </Toolbar>
      </AppBar>
      {selectedTab === 0 && <SerialPage />}
      {selectedTab === 1 && <CompletedPage />}
      {selectedTab === 2 && <PopularPage />}
    </MaterialGlobalStyle>
  );
};

export default WebtooNavPage;
