import React from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import Header from "./Component/Header";
import NavMenu from "./Component/NavMenu";
import WebtoonNavPage from "./Component/Page/WebtoonNavPage";
import { MaterialGlobalStyle, GlobalStyle } from "./GlobalStyles";
import BookMarkPage from "./Component/Page/BookMarkPage";
import HistoryPage from "./Component/Page/HistoryPage";
import ContentPage from "./Component/Page/ContentPage";
import LinkPage from "./Component/Page/LinkPage";
import SearchPage from "./Component/Page/SearchPage";
import SubPage from "./Component/Page/SubPage/SubPage";
import AutoComplete from "./Component/Button/AutoComplete";

// 공통 Header와 NavMenu 컴포넌트
function CommonHeaderAndNav() {
  return (
    <>
      <Header />
      <NavMenu />
    </>
  );
}

// 페이지 컴포넌트 정의
const pages = [
  { path: "/", component: WebtoonNavPage },
  { path: "/webtoon", component: WebtoonNavPage },
  { path: "/bookmark", component: BookMarkPage },
  { path: "/history", component: HistoryPage },
  { path: "/subpage", component: SubPage },
  { path: "/content", component: ContentPage },
  { path: "/linkpage", component: LinkPage },
  { path: "/autocomplete", component: AutoComplete }, //아래 search를 쓰게되면 disable
  { path: "/search", component: SearchPage },
];

function App() {
  return (
    <>

      <MaterialGlobalStyle>
        <GlobalStyle />
        <Router>
          <div className="">
            <Routes>
              {pages.map((page, index) => (
                <Route
                  key={index}
                  path={page.path}
                  element={<ContainHeaderAndNav component={page.component} />}
                />
              ))}
            </Routes>
          </div>
        </Router>
      </MaterialGlobalStyle>
    </>
  );
}
function ContainHeaderAndNav({ component: Component }) {
  const location = useLocation();
  const isNotNeedNav = location.pathname === "/linkpage" || location.pathname === "/autocomplete";
  // const isOnlyHeader = location.pathname === "/search";

  if (isNotNeedNav) {
    return <Component />;
  } else {
    return (
      <>
        <CommonHeaderAndNav />
        <Component />
      </>
    );
  }
}

export default App;
