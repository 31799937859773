import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";

const LinkPage = () => {
  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#2A2A2A",
    color: "white",
    // height: "100vh",
  };

  const cardContainerStyle = {
    display: "flex",
    flexDirection: "column", // 세로로 정렬
    gap: "20px", // Card 간 간격을 설정
    margin: "0 30px",
    padding: "24px 15px",
    textAlign: "center",
  };

  const titleStyle = {
    fontSize: "30px",
    fontWeight: "500",
    marginTop: "40px",
    marginBottom: "8px",
  };

  const pStyle = {
    fontSize: "18px",
    fontWeight: 300,
    display: "block",
    marginBlockStart: "0",
    marginBlockEnd: "0",
    marginInlineStart: "35px",
    marginInlineEnd: "35px",
    lineHeight: 1.5,
    textAlign: "center",
    marginBottom: "0px",
  };

  return (
    <div style={containerStyle}>
      <span style={titleStyle}>바바툰</span>
      <div>
        <p style={pStyle}>
          현재 페이지를 즐겨찾기에 추가하시면 항상 바바툰 실시간 도메인 주소를 확인하실 수 있습니다
        </p>
      </div>
      <div style={cardContainerStyle}>
        <Card>
          <CardContent>
            <Typography variant="h5" component="div" fontSize="28px">
              바바툰 BaBa<span style={{ color: "#dc3545", fontWeight: "bolder" }}>Toon</span>
            </Typography>
            <Typography variant="body2" color="#7c8085" fontSize="16px">
              가장 많은 무료웹툰을 보유하고 있으며 빠른 업데이트와 편의성으로 네이버웹툰, 다음웹툰,
              카카오웹툰, 레진코믹스 웹툰 서비스 제공.
            </Typography>
            <Button
              style={{
                fontSize: "20px",
                fontWeight: "bolder",
                backgroundColor: "#dc3545", // 원하는 배경 색상
                color: "white", // 원하는 글자색
                padding: "10px 70px", // 원하는 패딩
                marginTop: "20px",
                marginBottom: "-5px",
              }}
            >
              바로가기
            </Button>
          </CardContent>
        </Card>
        <Card>
          <CardContent>
            <Typography variant="h5" component="div" fontSize="28px">
              바바툰 <span style={{ color: "#007bff", fontWeight: "bolder" }}>Twitter</span>
            </Typography>
            <Typography variant="body2" color="#7c8085" fontSize="16px">
              가장 많은 무료웹툰을 보유하고 있으며 빠른 업데이트와 편의성으로 네이버웹툰, 다음웹툰,
              카카오웹툰, 레진코믹스 웹툰 서비스 제공.
            </Typography>
            <Button
              style={{
                fontSize: "20px",
                fontWeight: "bolder",
                backgroundColor: "#007bff", // 원하는 배경 색상
                color: "white", // 원하는 글자색
                padding: "10px 70px", // 원하는 패딩
                marginTop: "20px",
                marginBottom: "-5px",
              }}
            >
              바로가기
            </Button>
          </CardContent>
        </Card>
        <Card>
          <CardContent>
            <Typography variant="h5" component="div" fontSize="28px">
              바바툰 BaBa<span style={{ color: "#dc3545", fontWeight: "bolder" }}>Toon</span>
            </Typography>
            <Typography variant="body2" color="#7c8085" fontSize="16px">
              가장 많은 무료웹툰을 보유하고 있으며 빠른 업데이트와 편의성으로 네이버웹툰, 다음웹툰,
              카카오웹툰, 레진코믹스 웹툰 서비스 제공.
            </Typography>
            <Button
              style={{
                fontSize: "20px",
                fontWeight: "bolder",
                backgroundColor: "#dc3545", // 원하는 배경 색상
                color: "white", // 원하는 글자색
                padding: "10px 70px", // 원하는 패딩
                marginTop: "20px",
                marginBottom: "-5px",
              }}
            >
              바로가기
            </Button>
          </CardContent>
        </Card>
      </div>
      <p>
        <span style={{ color: "#ffffff80" }}>Copyright ©</span>바바툰,
        <span style={{ color: "#ffffff80" }}>by </span>
        BaBaToon.net.
      </p>
    </div>
  );
};

export default LinkPage;
