import React, { useEffect, useState } from "react";
import { GlobalStyle, MaterialGlobalStyle } from "../../GlobalStyles";
import Button from "@mui/material/Button";
import { AppBar, Toolbar } from "@mui/material";
import { useCookies } from "react-cookie"; // react-cookie에서 useCookies 가져오기
import PropShortCutPage from "./PropShortCutPage";

const NoResultAppBar = () => {
  const resultTextStyle = {
    color: "white",
    fontSize: "18px",
    fontWeight: "500",
    display: "block",
  };

  return (
    <AppBar position="static" sx={{ backgroundColor: "black", marginTop: "15px" }}>
      <Toolbar sx={{ display: "flex", justifyContent: "center" }}>
        <span style={resultTextStyle}>검색 결과가 없습니다.</span>
      </Toolbar>
    </AppBar>
  );
};

const HistoryPage = () => {
  const containerStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  };

  const bookMarkTextStyle = {
    color: "white",
    fontSize: "20px",
    fontWeight: "500",
    textAlign: "left",
    float: "left",
    display: "block",
    margin: "15px 15px",
  };

  const buttonStyle = {
    backgroundColor: "#dc3545",
    borderColor: "#dc3545",
    color: "white",
    fontSize: "16px",
    fontWeight: "bold",
    margin: "15px 15px",
    padding: "5px 5px",
  };

  const [badgeData, setBadgeData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // react-cookie의 useCookies를 사용하여 쿠키 가져오기
  const [cookies, removeCookie] = useCookies(["historyTitle"], {
    historyTitle: [], // 초기에 빈 배열로 설정
  });

  // console.log("Cookies:", cookies);
  // console.log(cookies.historyTitle);

  const handleClearHistory = () => {
    // "historyTitle"으로 시작하는 모든 쿠키 삭제
    Object.keys(cookies).forEach((cookieName) => {
      if (cookieName.startsWith("historyTitle")) {
        // console.log("cookieName:" + cookieName);
        removeCookie(cookieName); // 쿠키 삭제
      }
    });

    setBadgeData([]); // badgeData 초기화
  };

  useEffect(() => {
    // bookmarkArr에서 title만 추출하여 새로운 배열을 생성
    console.log("Cookies:");
    console.log(cookies);
    const historyArr = Object.entries(cookies || [])
      .filter(([name]) => name.startsWith("historyTitle"))
      .map(([name, value]) => value);
    console.log("historyArr:");
    console.log(historyArr);

    const titles = [...new Set(historyArr.map((title) => title))];
    console.log("titles");
    console.log(titles);

    // titles 배열이 비어있을 경우 쿼리를 보내지 않음
    if (titles.length === 0) {
      setBadgeData([]);
      setIsLoading(false);
      return;
    }

    // titles 배열을 URL 쿼리 매개변수로 서버에 보내기
    const queryString = titles.map((title) => `title=${encodeURIComponent(title)}`).join("&");
    const endPoint = "histories?";
    const apiURL = `${process.env.REACT_APP_API_URL}${endPoint}${queryString}`;

    fetch(apiURL, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        setBadgeData(data);
        setIsLoading(false);
        // console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching webtoon summaries:", error);
      });
  }, [cookies.historyTitle]); // historyTitle 쿠키가 변경될 때마다 이펙트 실행

  const handleBadgeDataChange = (newBadgeData) => {
    setBadgeData(newBadgeData);
  };

  return (
    <>
      <MaterialGlobalStyle>
        <GlobalStyle />
        <div style={containerStyle}>
          <h3 style={bookMarkTextStyle}>시청기록</h3>
          <Button
            variant="contained"
            sx={{
              ...buttonStyle,
              "&:hover": {
                backgroundColor: buttonStyle.backgroundColor, // 원래의 배경색을 유지
              },
            }}
            onClick={handleClearHistory}
          >
            시청기록 비우기
          </Button>
        </div>

        {isLoading ? null : badgeData.length === 0 ? <NoResultAppBar /> : null}
        {badgeData.length !== 0 && (
          <PropShortCutPage
            dbDatas={badgeData}
            type="history"
            onBadgeDataChange={handleBadgeDataChange}
          />
        )}
      </MaterialGlobalStyle>
    </>
  );
};

export default HistoryPage;
