import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { GlobalStyle } from "../../GlobalStyles";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate } from "react-router-dom";
import PopularSearch from "../Card/PopularSearch";
import InputSearch from "../Input/InputSearch";
import PropShortCutPage from "../Page/PropShortCutPage";

const AutoComplete = () => {
  const [inputValue, setInputValue] = useState("");
  const [isHaveInputValue, setIsHaveInputValue] = useState(false);
  const [dropDownList, setDropDownList] = useState([]);
  const [wholeTextArray, setWholeTextArray] = useState([]);
  const [dropDownItemIndex, setDropDownItemIndex] = useState(-1);
  const [enterPressed, setEnterPressed] = useState(false);
  const [badgeData, setBadgeData] = useState([]);

  const containsSubstring = (str, sub) => {
    const normalizedStr = str.replace(/\s/g, "");
    const normalizedSub = sub.replace(/\s/g, "");
    return normalizedStr.includes(normalizedSub);
  };

  const navigate = useNavigate(); // useNavigate 훅을 사용하여 네비게이션 함수를 가져옵니다
  const handleGoBack = () => {
    navigate(-1); // -1을 사용하여 뒤로 이동
  };

  const apiURL = `${process.env.REACT_APP_API_URL}title_summaries`;
  useEffect(() => {
    const fetchData = async () => {
      const startTime = performance.now(); // 호출 전 시간 기록

      try {
        const response = await fetch(apiURL);
        const data = await response.json();

        const endTime = performance.now(); // 호출 후 시간 기록
        const elapsedTime = endTime - startTime; // 걸린 시간 계산
        console.log(`API call took ${elapsedTime} milliseconds`);
        // console.log("title_summaries:", data);

        const extractedTitles = data.map((item) => item.Title.replace(/^UP\s\s*/, ""));
        setWholeTextArray(extractedTitles);
        console.log(wholeTextArray);
      } catch (error) {
        console.error("Error fetching title_summaries :", error);
      }
    };

    fetchData();
  }, [apiURL]);

  const highlightSubstring = (str, sub) => {
    if (!str || !sub) {
      return str;
    }
    const normalizedSub = sub.replace(/\s/g, "");
    const result = str.split("").map((char, index) => {
      if (normalizedSub.includes(char)) {
        return <span style={{ fontWeight: "800", color: "orange" }}>{char}</span>;
      }
      return char;
    });

    return result;
  };

  const showDropDownList = () => {
    if (inputValue === "") {
      setIsHaveInputValue(false);
      setDropDownList([]);
      setDropDownItemIndex(-1);
      setBadgeData([]); // inputValue가 비어있을 때 badgeData를 빈 배열로 설정
    } else {
      const choosenTextList = wholeTextArray.filter((textItem) => {
        const inputArray = inputValue.split("");
        const textArray = textItem.split("");
        let matchCount = 0;

        for (let i = 0; i < inputArray.length; i++) {
          if (textArray.includes(inputArray[i])) {
            matchCount++;
            textArray.splice(textArray.indexOf(inputArray[i]), 1);
          }
        }

        return matchCount === inputArray.length;
      });

      setDropDownList(choosenTextList);
    }
  };

  const changeInputValue = (event) => {
    setInputValue(event.target.value);
    setIsHaveInputValue(true);
  };

  const clickDropDownItem = (clickedItem) => {
    setInputValue(clickedItem);
    setIsHaveInputValue(false);
  };

  function getShortCut(title) {
    console.log("[getShortCut] title:", title);

    const serial_shortcuts = "full_serial_shortcuts";
    const completed_shortcuts = "full_completed_shortcuts";

    const serialURL = `${
      process.env.REACT_APP_API_URL
    }${serial_shortcuts}?title=${encodeURIComponent(title)}`;
    const completedURL = `${
      process.env.REACT_APP_API_URL
    }${completed_shortcuts}?title=${encodeURIComponent(title)}`;
    
    // 여러 개의 API 요청을 병렬로 보내기
    Promise.all([fetch(serialURL), fetch(completedURL)])
      .then((responses) => Promise.all(responses.map((response) => response.json())))
      .then((data) => {
        // data[0]: serial_shortcuts 데이터, data[1]: completed_shortcuts
        // data 배열에 있는 모든 데이터를 하나의 배열로 결합
        const combinedData = data.flat();

        setBadgeData(combinedData);
        console.log(combinedData); // 데이터 출력
      })
      .catch((error) => {
        console.error("Error fetching shortcuts data:", error);
      });
  }

  const handleDropDownKey = (event) => {
    if (inputValue !== "") {
      if (event.key === "ArrowDown" && dropDownList.length - 1 > dropDownItemIndex) {
        setDropDownItemIndex(dropDownItemIndex + 1);
      }
      if (event.key === "ArrowUp" && dropDownItemIndex >= 0) {
        setDropDownItemIndex(dropDownItemIndex - 1);
      }
      if (event.key === "Enter") {
        if (dropDownItemIndex >= 0) {
          clickDropDownItem(dropDownList[dropDownItemIndex]);
          // console.log("dropDownList[dropDownItemIndex]:" + dropDownList[dropDownItemIndex]);
          getShortCut(dropDownList[dropDownItemIndex]);
        } else {
          // console.log("inputValue:" + inputValue);
          getShortCut(inputValue);
        }

        setEnterPressed(true);
        setDropDownList([]);
        setDropDownItemIndex(-1);
      } else {
        setEnterPressed(false);
      }
    }
  };

  const handleClearClick = () => {
    setInputValue(""); // ClearIcon을 클릭하면 inputValue를 비웁니다.
    setDropDownItemIndex(-1);
    setIsHaveInputValue(false);
    setDropDownList([]);
    setBadgeData([]);
  };

  useEffect(showDropDownList, [inputValue]);

  function isValidText() {
    return isHaveInputValue && inputValue.trim() !== "";
  }

  const handleBadgeDataChange = (newBadgeData) => {
    setBadgeData(newBadgeData);
  };

  useEffect(() => {
    // badgeData가 변경될 때 수행할 작업을 여기에 추가
  }, [badgeData]); // badgeData를 의존성 배열로 지정

  const handleDropDownItemClick = (dropDownItem) => {
    getShortCut(dropDownItem);
  };

  return (
    <>
      <GlobalStyle />
      <WholeBox>
        <div style={containerStyle}>
          <ArrowBackIosIcon style={{ ...arrowStyle }} onClick={handleGoBack} />
          <InputSearch
            inputValue={inputValue}
            isHaveInputValue={isHaveInputValue}
            changeInputValue={changeInputValue}
            handleClearClick={handleClearClick}
            handleDropDownKey={handleDropDownKey}
          />
        </div>
        {isValidText() && !enterPressed && (
          <DropDownBox>
            {dropDownList.length === 0 && <DropDownItem>해당하는 단어가 없습니다</DropDownItem>}
            {dropDownList.map((dropDownItem) => (
              <DropDownItem
                key={dropDownItem}
                inputValue={inputValue}
                onClick={() => clickDropDownItem(dropDownItem)}
                onMouseOver={() => setDropDownItemIndex(dropDownList.indexOf(dropDownItem))}
                onMouseDown={() => handleDropDownItemClick(dropDownItem)}
                className={
                  dropDownItemIndex === dropDownList.indexOf(dropDownItem) ? "selected" : ""
                }
              >
                {inputValue && containsSubstring(dropDownItem, inputValue)
                  ? highlightSubstring(dropDownItem, inputValue)
                  : dropDownItem}
              </DropDownItem>
            ))}
          </DropDownBox>
        )}
        {!isValidText() && !inputValue && <PopularSearch />}
      </WholeBox>
      {badgeData.length !== 0 && (
        <PropShortCutPage
          dbDatas={badgeData}
          type="search"
          onBadgeDataChange={handleBadgeDataChange}
        />
      )}
    </>
  );
};

const arrowStyle = {
  fontSize: "30px",
  marginRight: "5px",
  cursor: "pointer",
};

const containerStyle = {
  display: "flex",
  alignItems: "center",
  margin: "30px 15px",
};

const WholeBox = styled.div`
  padding: 10px;
`;

const DropDownBox = styled.ul`
  display: block;
  margin: 0 auto;
  padding: 8px 0;
  background-color: black;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-top: none;
  border-radius: 0px 0px 16px 16px;
  box-shadow: 0 10px 10px rgb(0, 0, 0, 0.3);
  list-style-type: none;
  z-index: 3;
`;

const DropDownItem = styled.li`
  color: white;
  padding: 5px 16px;
  &.selected {
    color: #fc9c34;
    background-color: black;
  }

  span {
    font-weight: bold;
  }
`;

export default AutoComplete;
