import React, { useState } from "react";
import { Dialog, DialogTitle, DialogContent, Button } from "@mui/material";
import SeriesInfoCard from "../Card/SeriesInfoCard";
import CancelIcon from '@mui/icons-material/Cancel';

const titleStyle = {
  fontSize: "16px",
  fontWeight: "400",
  borderBottom: "1px solid #393c4a",
  padding: "8px 16px",
};

const contentStyle = {
  fontSize: "16px", // 원하는 스타일을 적용하세요
  padding: "16px",
  marginTop: "-5px",
  backgroundColor: "#212529", // 배경색 설정
  color: "#6c757d", // 글자색 설정
};

const flexContainerStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};

const cancelIconStyle = {
  cursor: "pointer",
  marginRight:"15px",
};

const sortButtonSx = {
  fontFamily: "Pretendard, sans-serif",
  color: "white",
  border: "1px solid white",
  padding: "0px 10px", // 좌우 패딩 추가
  marginTop: "-5px",
  marginRight: "15px",
  fontSize: "14px",
  "&:hover": {
    color: "white",
    backgroundColor: "#6C757D",
  },
};

const EpisodeDialog = ({ summary, episode, isOpen, onClose, onAlignClick  }) => {
  const [isReversed, setIsReversed] = useState(false);
  const handleSortClick = (e) => {
    e.stopPropagation(); // 이벤트 전파 중지
    onAlignClick(e); // 부모 컴포넌트로 이벤트 전달
    setIsReversed(!isReversed);
  };
  

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth="md"
      fullWidth={true}
      PaperProps={{
        style: {
          width: "90%",
          height: 465,
          backgroundColor: "#212529",
          color: "white",
        },
      }}
    >
      <div style={flexContainerStyle}>
        <DialogTitle sx={titleStyle}>나 홀로 무공</DialogTitle>
        <CancelIcon onClick={onClose} style={cancelIconStyle} />
      </div>
      <div style={flexContainerStyle}>
        <DialogContent sx={contentStyle}>연재 총47화</DialogContent>
        <Button sx={sortButtonSx} onClick={handleSortClick} >정렬</Button>
      </div>
      <DialogContent>
        <SeriesInfoCard
          summary={summary}
          curEpisode={episode}
          isReversed={isReversed}
          showDate={false}
        />
      </DialogContent>
    </Dialog>
  );
};

export default EpisodeDialog;
