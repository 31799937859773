import React, { useState } from "react";
import { GlobalStyle, MaterialGlobalStyle } from "../../GlobalStyles";
import ShortCutPage from "./ShortCutPage";
import DaysButton from "../Button/DaysButton";
import PlatformButton from "../Button/PlatformButton";
import GenreButton from "../Button/GenreButton";
import LatestOrPopularButton from "../Button/LatestOrPopularButton";
import {platformImageMap, genreLabels} from '../Card/ShortCutCard/PlatformMapGenreLabels';

const SerialPage = () => {
  const dayLabels = ["UP", "월", "화", "수", "목", "금", "토", "일", "10"];
  const [day, setDay] = useState(0);
  const [platform, setPlatform] = useState(0);
  const [genre, setGenre] = useState(0);
  const [order, setOrder] = useState(0);

  const platformLabels = Object.entries(platformImageMap).map(([label, platformInfo]) => ({
    label,
    iconSrc: platformInfo ? platformInfo.image : null,
  }));

  const handleDayBtnClick = (newValue) => {
    setDay(newValue);
  };

  const handlePlatformBtnClick = (newValue) => {
    setPlatform(newValue);
    // console.log("newValue:" + platformLabels[platform].label);
  };

  const handleGenreBtnClick = (newValue) => {
    setGenre(newValue);
  };

  const handleOrderBtnClick = (newValue) => {
    setOrder(newValue);
  };

  return (
    <MaterialGlobalStyle>
      <GlobalStyle />
      <DaysButton
        handleDayBtnClick={handleDayBtnClick}
        selectedDay={day} // selectedDay 값을 전달
        dayLabels={dayLabels}
      />
      <PlatformButton
        handlePlatformBtnClick={handlePlatformBtnClick}
        selectedPlatform={platform}
        platformLabels={platformLabels}
      />
      <GenreButton
        handleGenreBtnClick={handleGenreBtnClick}
        selectedGenre={genre}
        genreLabels={genreLabels}
      />
      <LatestOrPopularButton handleOrderBtnClick={handleOrderBtnClick} selectedOrder={order} />
      <ShortCutPage
        type="serial"
        selectedDay={dayLabels[day]}
        platform={Object.keys(platformImageMap)[platform]}
        genre={genreLabels[genre]}
      />
    </MaterialGlobalStyle>
  );
};

export default SerialPage;
