import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/system";
import { AppBar, Tab, Tabs } from "@mui/material";
import { Global, GlobalStyle, MaterialGlobalStyle, MUITheme } from "../GlobalStyles";
import { useNavigate } from "react-router-dom";

const appBarBGColor = "#0B0B1C";

const NavMenu = () => {
  const theme = useTheme(MUITheme);
  const [selectedTab, setSelectedTab] = useState(0);
  const navigate = useNavigate(); // useNavigate 훅 사용

  useEffect(() => {
    // 페이지에 따라 선택된 탭을 설정
    const pathToTab = {
      "/": 0,
      "/bookmark": 1,
      "/history": 2,
      "/linkpage": 3,
    };

    const currentPath = window.location.pathname;
    setSelectedTab(pathToTab[currentPath] || 0);
  }, []);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleTabClick = (path, newTab) => () => {
    if (newTab) {
      // 클릭한 탭에 해당하는 경로를 새 창에서 엽니다.
      window.open(path, "_blank");
    } else {
      navigate(path); // 현재 창에서 이동
    }
  };

  const tabs = [
    { label: "웹툰", path: "/" },
    { label: "북마크", path: "/bookmark" },
    { label: "시청기록", path: "/history" },
    { label: "최신주소", path: "/linkpage" },
  ];

  const tabSx = {
    fontSize: "16px",
    fontWeight: "bold",
    fontFamily: "Pretendard, sans-serif",
    color: theme.palette.primary.main,
    "&.Mui-selected": {
      color: theme.palette.secondary.main,
    },
    "&:hover": {
      color: "white", // 마우스가 올려졌을 때 색상
      // textDecoration: "underline", // 마우스 호버 시 밑줄 추가
    },
    margin: "0px 0px", // [상하][좌우]
  };

  return (
    <>
      <MaterialGlobalStyle>
        <GlobalStyle />
        <AppBar
          position="relative"
          sx={{
            backgroundColor: appBarBGColor,
            // flexWrap: "wrap" //여러 줄에 나눠배치 하는 부분 삭제
            justifyContent: "left",
            maxWidth: "1170px", // 최대 너비 설정
            margin: "10px auto 0", // 수정된 부분
          }}
        >
          <Tabs
            TabIndicatorProps={{
              style: {
                backgroundColor: Global.redEnable,
              },
            }}
            value={selectedTab}
            onChange={handleChange}
            variant={"fullWidth"}
            scrollButtons={true}
          >
            {tabs.map((tab, index) => (
              <Tab
                key={index}
                label={tab.label}
                sx={tab.label === "최신주소" ? { ...tabSx, color: "orange" } : tabSx}
                onClick={handleTabClick(tab.path, tab.label === "최신주소")}
              />
            ))}
          </Tabs>
        </AppBar>
      </MaterialGlobalStyle>
    </>
  );
};

export default NavMenu;
