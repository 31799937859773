import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Grid, Box } from "@mui/material";
import { GlobalStyle, MaterialGlobalStyle } from "../GlobalStyles";
import InputText from "./Input/InputText";

// Styled components로 스타일 적용
const HeaderContainer = styled.div`
  /* 최대 너비와 중앙 정렬 설정 */
  max-width: 1170px;
  margin: 0 auto;
`;

const Header = () => {
  const navigate = useNavigate();
  const handleHeaderClick = () => {
    navigate("/");
    window.location.reload();
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <MaterialGlobalStyle>
      <GlobalStyle />
      <HeaderContainer>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Box mt={1} onClick={handleHeaderClick} style={{ cursor: "pointer" }}>
              <img
                src="/images/Logo.png"
                alt="Logo"
                style={{
                  width: windowWidth <= 600 ? "70%" : "100%", // 윈도우 너비가 600px 이하이면 70%, 그 외에는 100%
                  maxWidth: "200px", // 화면 너비가 600px 이하일 때 최대 너비를 200px로 제한
                  marginBottom: "-10px",
                }}
              />
            </Box>
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              alignItems: "center",
              marginTop: "13px",
              marginRight: "25px",
            }}
          >
            <InputText type="header" />
          </Grid>
        </Grid>
      </HeaderContainer>
    </MaterialGlobalStyle>
  );
};

export default Header;
