import React, { useEffect, useRef, useState } from "react";
import { CardMedia } from "@mui/material";

const LazyLoadImage = ({ src, alt, style }) => {
  const [isIntersecting, setIntersecting] = useState(false);
  const imageRef = useRef(null);

  const handleIntersection = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        setIntersecting(true);
      }
    });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    });

    if (imageRef.current) {
      observer.observe(imageRef.current);
    }

    return () => {
      if (imageRef.current) {
        observer.unobserve(imageRef.current);
      }
    };
  }, []);

  // console.log("isIntersecting:", isIntersecting);

  return (
    <div style={{ position: "relative" }}>
      <CardMedia
        component="img"
        width={style.width}
        height={style.height}
        sx={{}}
        image={src}
        alt={alt}
        ref={imageRef}
      />
      {/* {isIntersecting ? (
        <CardMedia
          component="img"
          width={style.width}
          height={style.height}
          sx={{}}
          image={src}
          alt={alt}
          ref={imageRef}
        />
      ) : (
        <img
          src="images/camera_new.png"
          alt="Image1"
          style={{
            maxWidth: "50px",
            maxHeight: "50px",
            objectFit: "contain",
            margin: "auto",
            display: "block",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, 50%)",
            opacity: 0.3,
          }}
        />
      )} */}
    </div>
  );
};

export default LazyLoadImage;
