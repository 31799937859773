import { TextField, styled } from "@mui/material";
import React, { useState } from "react";
import { Global } from "../../GlobalStyles";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";

const CssTextField = styled(TextField)(({ type }) => ({
  "& label": { color: Global.greyDefault },
  "& label.Mui-focused": { color: Global.greyDefault },
  "& .MuiOutlinedInput-root": {
    color: Global.greyDefault,
    "& fieldset": {
      borderColor: Global.greyDefault,
    },
    "&.Mui-focused fieldset": {
      borderColor: Global.greyDefault,
    },
    width: type === "search" ? "300px" : "170px", // 변경된 너비
  },
}));

const StyledSearchIcon = styled(SearchIcon)({
  color: Global.greyDefault,
  transition: "color 0.3s",
  "&:hover": {
    color: "white",
    backgroundColor: Global.greyDefault,
  },
  cursor: "pointer", // 아이콘에 클릭 이벤트를 추가하기 위해 필요
});

const InputText = ({ type }) => {
  const [inputValue, setInputValue] = useState(""); // 입력값을 상태로 관리
  const navigate = useNavigate();

  const handleSearch = () => {
    console.log("Input Value:", inputValue);
    // 여기에서 추가적인 작업을 수행할 수 있습니다.

    const serial_shortcuts = "full_serial_shortcuts";
    const completed_shortcuts = "full_completed_shortcuts";

    const serialURL = `${
      process.env.REACT_APP_API_URL
    }${serial_shortcuts}?title=${encodeURIComponent(inputValue)}`;
    const completedURL = `${
      process.env.REACT_APP_API_URL
    }${completed_shortcuts}?title=${encodeURIComponent(inputValue)}`;

    // 여러 개의 API 요청을 병렬로 보내기
    Promise.all([fetch(serialURL), fetch(completedURL)])
      .then((responses) => Promise.all(responses.map((response) => response.json())))
      .then((data) => {
        const combinedData = data.flat();

        // setBadgeData(combinedData);
        console.log(combinedData);

        // Navigate to "/search" and pass "combinedData" as state
        navigate("/search", { state: { combinedData } });
      })
      .catch((error) => {
        console.error("Error fetching shortcuts data:", error);
      });
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const handleIconClick = () => {
    handleSearch();
  };

  // TODO 자동검색시 사용
  // const navigate = useNavigate();
  // const handleSearchClick = () => {
  //   if (type === "header") {
  //     navigate("/autocomplete");
  //   }
  // };
  return (
    <div>
      <CssTextField
        type={type}
        id="outlined-basic"
        label="제목/작가"
        variant="outlined"
        size="small"
        value={inputValue}
        // onClick={handleSearchClick} // TODO : autocomplete 사용시 enable
        onChange={(e) => setInputValue(e.target.value)}
        onKeyPress={handleKeyPress}
        InputProps={{
          endAdornment: <StyledSearchIcon onClick={handleIconClick} />,
        }}
      />
    </div>
  );
};

export default InputText;
