// CardComponent.js
import React from "react";
import { Card, CardContent, Typography, CardMedia } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { cardStyles } from "./CardStyles";
import { platformImageMap } from "./PlatformMapGenreLabels";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import LazyLoadImage from "./LazyLoadImage";

const theme = createTheme({
  typography: {
    fontFamily: "'Pretendard', sans-serif",
  },
});

const renderGenres = (Genre, GenreStyle) => {
  if (Genre) {
    // console.log("Genre:", Genre);
    const genres = Genre.split(",").map((genre, index) => genre.trim());
    return genres.map((genre, index) => (
      <span key={index} style={{ ...GenreStyle }}>
        {genre}
      </span>
    ));
  }

  return null;
};

const CardComponent = ({ data, index, cardWidth, handleImageClick, handleDeleteClick, type }) => {
  //console.log("CardComponent:", type);
  const platformInfo = platformImageMap[data.PlatformName];
  const platformImage = platformInfo ? platformInfo.image : "images/etc.png";
  const {
    cardStyle,
    cardMediaStyle,
    cardContentStyle,
    PlatformImgStyle,
    getCreateAtStyle,
    AgeLimitStyle,
    GenreStyleContainer,
    GenreStyle,
    UpStyle,
    TitleStyle,
    LatestEpisodeStyle,
    avatarStyle,
    AuthorStyle,
    deleteBtnStyle,
    deleteIconStyle,
  } = cardStyles; // 불러온 스타일 파일을 사용합니다.

  return (
    <Card
      sx={{
        ...cardStyle,
        width: cardWidth,
        marginRight: "3px",
        marginLeft: "3px",
        position: "relative",
      }}
      key={index}
    >
      <div
        style={{ position: "relative", cursor: "pointer" }}
        onClick={() => handleImageClick(data.Title)}
      >
        <LazyLoadImage src={data.TitleImg} alt="Image1" style={cardMediaStyle} />
        <img
          src={platformImageMap[platformImage] || platformImage}
          alt="Image1"
          style={PlatformImgStyle}
        />
        <span style={getCreateAtStyle(data.isSerial)}>{data.createAt}</span>
        {data.AgeLimit !== 0 && data.AgeLimit !== null && (
          <span style={AgeLimitStyle}>{data.AgeLimit}</span>
        )}
        <div style={GenreStyleContainer}>{renderGenres(data.Genre, GenreStyle)}</div>
      </div>

      <ThemeProvider theme={theme}>
        <CardContent
          sx={{
            ...cardContentStyle,
            padding: "0px",
            "&:last-child": {
              paddingBottom: "0px", // 마지막 자식의 패딩 제거
            },
          }}
        >
          {data.isSerial === true && (
            <Typography variant="h3" component="div" style={UpStyle}>
              UP
            </Typography>
          )}
          <Typography
            variant="h3"
            component="div"
            style={{
              ...TitleStyle,
              left: data.isSerial === true ? "22px" : "4px",
            }}
          >
            {data.Title}
          </Typography>
          <Typography variant="h3" component="div" style={LatestEpisodeStyle}>
            {data.LatestEpisode}
          </Typography>
          <AccountCircleIcon src={data.avatar} style={avatarStyle} />
          <Typography variant="h3" component="div" style={AuthorStyle}>
            {data.Author}
          </Typography>
          {(type === "history" || type === "bookmark") && (
            <div style={deleteBtnStyle} onClick={() => handleDeleteClick(data.Title)}>
              <DeleteForeverIcon fontSize="small" style={deleteIconStyle} />
              삭제
            </div>
          )}
        </CardContent>
      </ThemeProvider>
    </Card>
  );
};

export default CardComponent;
