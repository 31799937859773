import React, { useState } from "react";
import { useTheme } from "@mui/system";
import { AppBar, Tab, Tabs } from "@mui/material";
import { Global, GlobalStyle, MaterialGlobalStyle, MUITheme } from "../../GlobalStyles";

const toolBarBGColor = "#232736";

const BestOfDayNav = () => {
  const theme = useTheme(MUITheme);
  const [selectedTab, setSelectedTab] = useState(0);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleTabClick = (path) => () => {};

  const tabs = [
    { label: "일간BEST", sortType: "sortDay" },
    { label: "주간BEST", sortType: "sortWeek" },
    { label: "월간BEST", sortType: "sortMonth" },
    { label: "실시간BEST", sortType: "sortLive" },
  ];

  const tabSx = {
    fontSize: "14px",
    fontWeight: "",
    fontFamily: "Pretendard, sans-serif",
    color: theme.palette.primary.main,
    "&.Mui-selected": {
      color: theme.palette.secondary.main,
    },
    "&:hover": {
      // 마우스가 올려졌을 때 색상
      color: "white",
      fontWeight: "bold",
    },
    margin: "0px -10px",
  };

  return (
    <>
      <MaterialGlobalStyle>
        <GlobalStyle />
        <AppBar position="relative" sx={{ backgroundColor: toolBarBGColor, marginTop: "0px" }}>
          <Tabs
            TabIndicatorProps={{
              style: {
                backgroundColor: Global.redEnable,
              },
            }}
            value={selectedTab}
            onChange={handleChange}
            variant="fullWidth"
          >
            {tabs.map((tab, index) => (
              <Tab
                key={index}
                label={tab.label}
                sx={tabSx}
                onClick={handleTabClick(tab.sortType)}
              />
            ))}
          </Tabs>
        </AppBar>
      </MaterialGlobalStyle>
    </>
  );
};

export default BestOfDayNav;
