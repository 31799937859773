import React from "react";
import { TextField } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import styled from "styled-components";
import { Global } from "../../GlobalStyles";

const InputSearch = ({
  inputValue,
  isHaveInputValue,
  changeInputValue,
  handleClearClick,
  handleDropDownKey,
}) => {
  return (
    <InputBox>
      <CssTextField
        id="outlined-basic"
        label="제목/작가"
        placeholder="제목, 작가를 입력하세요."
        variant="outlined"
        size="small"
        value={inputValue}
        onChange={changeInputValue}
        onKeyUp={handleDropDownKey}
        InputProps={{
          startAdornment: <StyledSearchIcon />,
          endAdornment: inputValue && (
            <ClearIcon onClick={handleClearClick} style={{ cursor: "pointer" }} />
          ),
        }}
      />
    </InputBox>
  );
};

const CssTextField = styled(TextField)(() => ({
  "& label": { color: Global.greyDefault },
  "& label.Mui-focused": { color: Global.greyDefault },
  "& .MuiOutlinedInput-root": {
    color: Global.greyDefault,
    "& fieldset": {
      borderColor: Global.greyDefault,
    },
    "&.Mui-focused fieldset": {
      borderColor: Global.greyDefault,
    },
    width: "270px",
  },
}));

const StyledSearchIcon = styled(SearchIcon)({
  color: Global.greyDefault,
  transition: "color 0.3s",
  "&:hover": {
    // color: "white",
  },
  marginRight: "5px",
});

const activeBorderRadius = "16px 16px 0 0";
const inactiveBorderRadius = "16px 16px 16px 16px";

const InputBox = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: ${(props) => (props.isHaveInputValue ? activeBorderRadius : inactiveBorderRadius)};
  z-index: 3;

  &:focus-within {
    box-shadow: 0 10px 10px rgb(0, 0, 0, 0.3);
  }
`;

export default InputSearch;
