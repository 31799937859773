// PlatformImageMap.js
const platformImageMap = {
  전체: null,
  naver: { image: "images/naver.png", name: "네이버" },
  daum: { image: "images/daum.png", name: "다음" },
  kakao: { image: "images/kakao.png", name: "카카오" },
  rejin: { image: "images/rejin.png", name: "레진코믹스" },
  toptoon: { image: "images/toptoon.png", name: "탑툰" },
  comica: { image: "images/comica.png", name: "코미카" },
  battlecomics: { image: "images/battlecomics.png", name: "배틀코믹스" },
  comicgt: { image: "images/comicgt.png", name: "코믹GT" },
  ktoon: { image: "images/ktoon.png", name: "KTOON" },
  anitoon: { image: "images/anitoon.png", name: "애니툰" },
  foxtoon: { image: "images/foxtoon.png", name: "폭스툰" },
  peanutoon: { image: "images/peanutoon.png", name: "피너툰" },
  bom: { image: "images/bom.png", name: "봄툰" },
  comico: { image: "images/comico.png", name: "코미코" },
  mootoon: { image: "images/mootoon.png", name: "무툰" },
  기타: { image: "images/etc.png", name: "기타" },
  // etc: "images/etc.png",
};

const genreLabels = [
  "전체",
  "학원",
  "액션",
  "SF",
  "스토리",
  "판타지",
  "BL/백합",
  "개그/코미디",
  "연애/순정",
  "드라마",
  "로맨스",
  "시대극",
  "스포츠",
  "일상",
  "추리/미스터리",
  "공포/스릴러",
  "성인",
  "옴니버스",
  "에피소드",
  "무협",
  "소년",
  "기타",
];

export { platformImageMap, genreLabels };
