import { Toolbar, Button } from "@mui/material";
import React, { useState } from "react";
import { Global, GlobalStyle, MaterialGlobalStyle } from "../../GlobalStyles";

const buttonSx = {
  width: "500px",
  fontFamily: "Pretendard, sans-serif",
  color: "white",
  fontSize: "13px",
  border: `1px solid ${Global.greyBorder}`, // 1px의 블랙 border 추가
  "&:hover": {
    color: "white",
    backgroundColor: Global.redHover,
  },
};
const PopularGenreButton = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const handleButtonClick = (newValue) => {
    setSelectedTab(newValue);
  };

  const getButtonStyle = (index) => ({
    ...buttonSx,
    backgroundColor: selectedTab === index ? Global.redHover : "",
  });
  return (
    <MaterialGlobalStyle>
      <GlobalStyle />
      <Toolbar sx={{ display: "flex", justifyContent: "space-between", padding: "0 10px" }}>
        <Button onClick={() => handleButtonClick(0)} sx={getButtonStyle(0)}>
          일반웹툰
        </Button>
        <Button onClick={() => handleButtonClick(1)} sx={getButtonStyle(1)}>
          완결
        </Button>
        <Button onClick={() => handleButtonClick(2)} sx={getButtonStyle(2)}>
          인기
        </Button>
      </Toolbar>
    </MaterialGlobalStyle>
  );
};

export default PopularGenreButton;
