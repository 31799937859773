import React from "react";
import { useLocation } from "react-router-dom";
import PropShortCutPage from "./PropShortCutPage";

const SearchPage = () => {
  const location = useLocation();
  const badgeData = location.state?.combinedData || [];

  const handleBadgeDataChange = (newBadgeData) => {
    // Do Nothing
  };

  return (
    <>
      <PropShortCutPage
        dbDatas={badgeData}
        type="search"
        onBadgeDataChange={handleBadgeDataChange}
      />
    </>
  );
};

export default SearchPage;
