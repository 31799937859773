import React, { useState } from "react";
import { GlobalStyle, MaterialGlobalStyle } from "../../GlobalStyles";
import ShortCutPage from "./ShortCutPage";
import PlatformButton from "../Button/PlatformButton";
import GenreButton from "../Button/GenreButton";
import LatestOrPopularButton from "../Button/LatestOrPopularButton";
import { platformImageMap, genreLabels } from '../Card/ShortCutCard/PlatformMapGenreLabels';

const CompletedPage = () => {
  const [platform, setPlatform] = useState(0);
  const [genre, setGenre] = useState(0);
  const [order, setOrder] = useState(0);

  const platformLabels = Object.entries(platformImageMap).map(([label, platformInfo]) => ({
    label,
    iconSrc: platformInfo ? platformInfo.image : null,
  }));

  const handlePlatformBtnClick = (newValue) => {
    setPlatform(newValue);
  };

  const handleGenreBtnClick = (newValue) => {
    setGenre(newValue);
  };

  const handleOrderBtnClick = (newValue) => {
    setOrder(newValue);
  };

  return (
    <MaterialGlobalStyle>
      <GlobalStyle />
      <PlatformButton
        handlePlatformBtnClick={handlePlatformBtnClick}
        selectedPlatform={platform}
        platformLabels={platformLabels}
      />
      <GenreButton
        handleGenreBtnClick={handleGenreBtnClick}
        selectedGenre={genre}
        genreLabels={genreLabels}
      />
      <LatestOrPopularButton handleOrderBtnClick={handleOrderBtnClick} selectedOrder={order} />
      <ShortCutPage
        type="completed"
        selectedDay="전체"
        platform={platformLabels[platform].label}
        genre={genreLabels[genre]}
      />
    </MaterialGlobalStyle>
  );
};

export default CompletedPage;
