import React from "react";
import { stSubPage, Global } from "../../../GlobalStyles";
import BookmarkAuthorButton from "./BookmarkAuthorButton";

const containerStyle = (windowSize) => ({
  marginLeft: "20px",
  marginRight: "20px",
  display: "flex",
  flexDirection: windowSize.width <= 576 ? "column" : "row",
  paddingBottom: "15px",
});

const outerContainerStyle = (windowSize) => ({
  display: "flex",
  flexDirection: "column", // 기본적으로 컨테이너를 세로로 배치
  marginLeft: windowSize.width > 576 ? "20px" : "0", // 윈도우 너비에 따라 여백을 추가
});

const textContainerStyle = (windowSize) => ({
  textAlign: "left",
  paddingBottom: "15px",
  borderBottom: `1px solid ${stSubPage.border}`,
});

const storyAndGenreContainer = (windowSize) => ({
  fontSize: "14px",
  display: "block",
  marginTop: "0",
});

const imageContainerStyle = {
  // display: "flex",
  justifyContent: "center",
};

const imageStyle = (windowSize) => ({
  borderRadius: "5px",
  marginTop: "10px",
  marginBottom: "-10px",
  // width={windowSize.width * 0.9}
  // height={(windowSize.width * 0.9 * 260) / 450}
  width: windowSize.width <= 576 ? "100%" : `${285}`,
  height: windowSize.width <= 576 ? "auto" : `${170}`,
});

const titleStyle = {
  fontSize: "25px",
  color: stSubPage.title,
  marginBottom: "7px",
};

const ageLimitStyle = {
  display: "inline-block",
  fontSize: "9px",
  backgroundColor: stSubPage.red,
  color: "white",
  padding: "4px 3px",
  borderRadius: "4px",
  marginRight: "10px",
  fontWeight: "700",
  lineHeight: "1",
  textAlign: "center",
  whiteSpace: "nowrap",
  verticalAlign: "baseline",
};

const platformImgStyle = {
  width: "17px",
  borderRadius: "4px",
  verticalAlign: "middle",
  overflowClipMargin: "content-box",
  lineHeight: "1.5",
  marginTop: "3px",
};

const platformNameStyle = {
  fontSize: "14px",
  marginLeft: "4px",
  verticalAlign: "bottom",
  color: stSubPage.title,
};

const authorStyle = {
  fontSize: "14px",
  marginLeft: "15px",
  verticalAlign: "bottom",
  color: stSubPage.title,
};

const summaryStyle = {
  fontSize: "14px",
  color: stSubPage.summary,
  borderBottom: `1px solid ${stSubPage.border}`,
  paddingBottom: "15px",
  display: "block",
};

const genreStyle = {
  fontSize: "14px",
  display: "block",
};

const genreBadgeStyle = (color, bgColor, type) => ({
  fontSize: "14px",
  backgroundColor: bgColor,
  color: color,
  padding: type === "연재일" ? "2px 6px" : "2px 4px",
  borderRadius: "4px",
  marginLeft: "5px",
  fontWeight: "700",
  lineHeight: "1",
  textAlign: "center",
  whiteSpace: "nowrap",
  verticalAlign: "baseline",
});

const renderGenres = (Genre) => {
  if (Genre) {
    const genres = Genre.split(",").map((genre, index) => genre.trim());
    return genres.map((genre, index) => (
      <span
        key={index}
        style={{ ...genreBadgeStyle(stSubPage.genreBadge, stSubPage.genreBadgeBG) }}
      >
        {genre}
      </span>
    ));
  }

  return null;
};

const renderUploadDay = (summary) =>
  summary.UploadDay !== "NA" && (
    <span>
      <span style={{ color: stSubPage.summary, marginLeft: "30px" }}>연재일</span>
      <span style={genreBadgeStyle(stSubPage.genreBadgeBG, Global.greyHover, "연재일")}>
        {summary.UploadDay}
      </span>
    </span>
  );

const WebtoonSummaryCard = ({
  summary,
  windowSize,
  platformImageMap,
  handleBookmarkClick,
  hasBookmark,
}) => {
  return (
    <div key={summary._id} style={containerStyle(windowSize)}>
      <div style={imageContainerStyle}>
        <img src={summary.TitleImg} alt="Webtoon Cover" style={imageStyle(windowSize)} />
      </div>
      <div style={outerContainerStyle(windowSize)}>
        <div style={textContainerStyle(windowSize)}>
          <h3 style={titleStyle}>{summary.Title}</h3>
          <div style={ageLimitStyle}>
            {summary.AgeLimit === 0 ? "ALL" : summary.AgeLimit}
            {summary.AgeLimit !== 0 ? "세" : ""}
          </div>
          <img
            src={
              (platformImageMap[summary.PlatformName] &&
                platformImageMap[summary.PlatformName].image) ||
              summary.platformImage
            }
            alt="Image1"
            style={platformImgStyle}
          />
          <span style={platformNameStyle}>
            {platformImageMap[summary.PlatformName]?.name || summary.PlatformName}
          </span>
          <span style={authorStyle}>작가 : {summary.Author}</span>
        </div>
        <div style={storyAndGenreContainer(windowSize)}>
          <p style={summaryStyle}>{summary.Story}</p>
          <p style={genreStyle}>
            <span style={{ color: stSubPage.summary }}>장르</span>
            {renderGenres(summary.Genre)}
            {summary.isSerial === true ? renderUploadDay(summary) : null}
          </p>
        </div>
        <BookmarkAuthorButton
          windowSize={windowSize}
          hasBookmark={hasBookmark}
          handleBookmarkClick={handleBookmarkClick}
        />
      </div>
    </div>
  );
};

export default WebtoonSummaryCard;
