import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { GlobalStyle, MaterialGlobalStyle } from "../../GlobalStyles";
import { useCookies } from "react-cookie";
import CardComponent from "../Card/ShortCutCard/CardComponent";

function PropShortCutPage({ dbDatas, type, onBadgeDataChange }) {
  const [newDbDatas, setNewDbDatas] = useState(dbDatas);
  const navigate = useNavigate();
  console.log("PropShortCutPage's type:" + type);
  console.log("newDbDatas:",newDbDatas);

  const calculateCardWidth = useCallback(() => {
    const windowWidth = window.innerWidth;
    const baseCardWidth = 150; // 기본 카드 너비
    const cardWidth = windowWidth > 600 ? "160px" : `${baseCardWidth - (486 - windowWidth) / 3}px`;
    return cardWidth;
  }, []);
  const [cardWidth, setCardWidth] = useState(calculateCardWidth);

  const handleResize = useCallback(() => {
    const newCardWidth = calculateCardWidth();
    setCardWidth(newCardWidth);
  }, [calculateCardWidth]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  const handleImageClick = (title) => {
    console.log(title);
    navigate(`/subpage?title=${encodeURIComponent(title)}`);
  };

  const [cookies, setCookie, removeCookie] = useCookies(["bookmarkTitle"]);

  const handleDeleteClick = (title) => {
    const itemToDelete = Object.keys(cookies).find(
      (name) => name.startsWith(`${type}Title`) && cookies[name] === title
    );
    console.log(itemToDelete);
    if (itemToDelete) {
      removeCookie(itemToDelete);
      console.log("쿠키가 삭제되었습니다.");

      setNewDbDatas((prevDbDatas) => prevDbDatas.filter((data) => !data.Title.includes(title)));
    }
  };

  useEffect(() => {
    onBadgeDataChange(newDbDatas);
  }, [newDbDatas, onBadgeDataChange]);

  return (
    <MaterialGlobalStyle>
      <GlobalStyle />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start", // 아이템을 왼쪽으로 정렬
          flexWrap: "wrap",
          maxWidth: "1170px",
          margin: "0 auto",
        }}
      >
        {newDbDatas.map((data, index) => (
          <div key={index}>
            <CardComponent
              data={data}
              index={index}
              cardWidth={cardWidth}
              handleImageClick={handleImageClick}
              handleDeleteClick={handleDeleteClick}
              type={type}
            />
          </div>
        ))}
      </div>
    </MaterialGlobalStyle>
  );
}

export default PropShortCutPage;
