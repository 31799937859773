import React, { useRef, useEffect } from "react";
import { AppBar } from "@mui/material";
import SubjectIcon from "@mui/icons-material/Subject";
import { useNavigate } from "react-router-dom"; // React Router의 useHistory hook 추가

const iconSubjectStyle = {
  fontSize: "25px",
  fontWeight: "bold",
  width: "25px",
  position: "fixed", // 고정 위치로 설정
  top: "0%",
  left: "0%",
  zIndex: 1,
  color: "white",
  backgroundColor: "#1b1e29",
  // backgroundColor: "red",
  display: "inline-block",
  padding: "17.5px 17.5px",
  borderRadius: "0px",
};

const ChapterHeaderNav = ({ episode, summary }) => {

  console.log("ChapterHeaderNav:");
  console.log("summary:", summary);
  console.log("LastestEpisode:", summary.SeriesInfo[0].EpisodeTitle);

  const navigate = useNavigate(); // useHistory hook을 사용하여 history 객체를 얻음
  const finalSeries = summary.SeriesInfo[0].EpisodeTitle.match(/(?:\d+화)$/);
  // console.log("finalSeries:", finalSeries[0]);
  
  // 아이콘의 너비 (고정값)
  const iconWidth = parseInt(iconSubjectStyle.width);

  // 텍스트 요소의 너비를 계산 (아이콘 너비 + 간격)
  const textWidth = iconWidth + 52; // 간격을 52px로 가정
  
  let firstTextPart = "";
  if (episode && episode.EpisodeTitle) {
    const parts = episode.EpisodeTitle.split(" - "); // EpisodeTitle이 존재하면 split 수행
    // parts 배열이 비어있지 않으면 첫 번째 요소 추출
    if (parts.length > 0) {
      firstTextPart = parts[0].replace(/^0+/, "");
    }
  }

  // infoText를 구성
  const infoText = `[ ${firstTextPart}화 / 총${finalSeries} ]`;
  // console.log("infoText:", infoText);

  // infoTextWidth를 저장할 useRef를 생성
  const infoTextWidthRef = useRef(null);

  useEffect(() => {
    // infoTextWidthRef에 실제 폭을 측정하여 저장
    infoTextWidthRef.current = calculateTextWidth(infoText);
  }, [infoText]);

  // infoTextWidthRef를 고려하여 시작 위치 조정
  const marginLeft = textWidth - infoTextWidthRef.current + iconWidth;

  // data.Title를 미리 수정한 변수를 생성
  const modifiedTitle = summary.Title.replace("UP\t\t ", "");
  // console.log("modifiedTitle:", modifiedTitle);

  const handleIconClick = (title) => {
    navigate(`/subpage?title=${encodeURIComponent(title)}`);
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        top: 0,
        height: "60px",
        backgroundColor: "#131417",
        display: "flex",
        justifyContent: "flex-start",
      }}
    >
      <div style={{ display: "flex", alignItems: "left" }}>
        <SubjectIcon
          fontSize="small"
          style={{ ...iconSubjectStyle }}
          onClick={() => handleIconClick(modifiedTitle)}
        />
        <span style={{ marginLeft: textWidth + "px", marginTop: "10px" }}>
          {episode.EpisodeTitle}
          <br />
          <span
            style={{
              marginLeft: marginLeft - 145,
              fontSize: "12px",
              color: "rgb(116, 118, 127)",
            }}
          >
            {infoText}
          </span>
        </span>
      </div>
    </AppBar>
  );
};

// 텍스트 폭을 계산하는 함수 (예시로 가정)
function calculateTextWidth(text) {
  return text.length;
}

export default ChapterHeaderNav;
