import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../Spinner/LoadingSpinner";
import { GlobalStyle, MaterialGlobalStyle } from "../../GlobalStyles";
import CardComponent from "../Card/ShortCutCard/CardComponent";
import { useInView } from "react-intersection-observer";

// applyFilters 함수를 여기로 이동
const applyFilters = (
  badgeData,
  type,
  selectedDay,
  platform,
  genre,
  setIsFiltered,
  setFilteredBadgeData
) => {
  let filteredBadgeData = badgeData;
  let isFiltered = false;

  if (type === "serial" && selectedDay !== "UP") {
    filteredBadgeData = filteredBadgeData.filter((data) => data.UploadDay === selectedDay);
    isFiltered = true;
  }

  if (type !== "popular") {
    if (platform !== "전체") {
      filteredBadgeData = filteredBadgeData.filter((data) => data.PlatformName === platform);
      isFiltered = true;
    }

    if (genre !== "전체") {
      filteredBadgeData = filteredBadgeData.filter((data) =>
        [data.Genre].includes(genre)
      );
      isFiltered = true;
    }
  }

  if (isFiltered) {
    setIsFiltered(true);
  }

  setFilteredBadgeData(filteredBadgeData);
};

const ShortCutPage = ({ type, selectedDay, platform, genre }) => {
  const [badgeData, setBadgeData] = useState([]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isFiltered, setIsFiltered] = useState(false);
  const [filteredBadgeData, setFilteredBadgeData] = useState(badgeData);
  const [dataRef, inView] = useInView({ triggerOnce: true });

  const calculateCardWidth = useCallback(() => {
    const windowWidth = window.innerWidth;
    const baseCardWidth = 150; // 기본 카드 너비
    const fixedCardWidth = 169; // 600px 이상일 때 한 개의 Card가 차지하는 width
    const cardsPerRow = windowWidth > 600 ? Math.floor(windowWidth / fixedCardWidth) : 3;
    const cardWidth = windowWidth > 600 ? "160px" : `${baseCardWidth - (486 - windowWidth) / 3}px`;
    return { cardWidth, cardsPerRow };
  }, []);

  const [cardWidth, setCardWidth] = useState(calculateCardWidth().cardWidth);
  const [cardsPerRow, setCardsPerRow] = useState(calculateCardWidth().cardsPerRow);

  const apiURL = `${process.env.REACT_APP_API_URL}${type}_shortcuts`;
  const fetchData = async () => {
    try {
      let url = apiURL;
      if (badgeData.length > 0) {
        const lastIdParam = `lastId=${badgeData[badgeData.length - 1]._id}`;
        url = `${apiURL}?limit=30&${lastIdParam}`;
        // console.log("lastIdParam:", lastIdParam);
        // console.log("url:", url);
      } else {
        url = `${apiURL}?limit=30`;
      }

      const response = await fetch(url);
      const data = await response.json();
      setBadgeData((prevData) => [...prevData, ...data]);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching webtoon summaries:", error);
      setIsLoading(false);
    }
  };

  // applyFilters 함수의 내용을 이동하고 필요한 부분을 수정
  const handleApplyFilters = () => {
    applyFilters(
      badgeData,
      type,
      selectedDay,
      platform,
      genre,
      setIsFiltered,
      setFilteredBadgeData
    );
  };

  const handleResize = useCallback(() => {
    const { cardWidth: newCardWidth, cardsPerRow: newCardsPerRow } = calculateCardWidth();
    setCardWidth(newCardWidth);
    setCardsPerRow(newCardsPerRow);
  }, [calculateCardWidth]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  const handleImageClick = (title) => {
    console.log("ShortCutPage title:",title);
    navigate(`/subpage?title=${encodeURIComponent(title)}`);
  };

  useEffect(() => {
    const fetchDataAndLog = async () => {
      console.log("초기 렌더링 useEffect([])");
      await fetchData();
    };

    fetchDataAndLog();
  }, []);

  useEffect(() => {
    handleApplyFilters();
  }, [selectedDay, platform, genre, type, badgeData]);

  useEffect(() => {
    if (inView) {
      console.log(inView, "[inView]]fetchData 호출");
      fetchData();
    }
  }, [inView]);

  

  return (
    <MaterialGlobalStyle>
      <GlobalStyle />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start", // 아이템을 왼쪽으로 정렬
          flexWrap: "wrap",
          maxWidth: "1170px",
          margin: "0 auto",
        }}
      >
        {(isFiltered ? filteredBadgeData : badgeData).map((data, index) => (
          <div key={index} ref={index < badgeData.length - cardsPerRow * 4 ? dataRef : null}>
            <CardComponent
              data={data}
              index={index}
              cardWidth={cardWidth}
              handleImageClick={handleImageClick}
              handleDeleteClick={undefined}
              type={type}
            />
          </div>
        ))}
      </div>
    </MaterialGlobalStyle>
  );
};

export default ShortCutPage;
