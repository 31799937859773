// 최신순 | 인기순 을 클릭하는 버튼

import React from "react";
import { Button, Toolbar } from "@mui/material";
import { Global } from "../../GlobalStyles";

const latestLabels = ["최신순", "인기순"];

const getLatestBtnStyle = (index, selectedOrder) => ({
  fontSize: "14px",
  width: "70px", // 폭을 줄임
  minWidth: "50px", // 최소 너비 제거
  height: "27px",
  fontFamily: "Pretendard, sans-serif",
  color: "white",
  borderColor: Global.greyBorder,
  borderWidth: "1px",
  borderStyle: "solid",
  backgroundColor: selectedOrder === index ? Global.redEnable : Global.greyBG,
  "&:hover": {
    color: "white",
    backgroundColor: selectedOrder === index ? Global.redHover : Global.greyHover,
  },
});
const LatestOrPopularButton = ({ handleOrderBtnClick, selectedOrder }) => {
  return (
    <Toolbar sx={{ display: "flex", justifyContent: "left", maxWidth: "1170px", margin: "10px auto 0" }}>
      {latestLabels.map((label, index) => (
        <Button
          key={index}
          onClick={() => handleOrderBtnClick(index)}
          sx={getLatestBtnStyle(index, selectedOrder)}
        >
          {label}
        </Button>
      ))}
    </Toolbar>
  );
};

export default LatestOrPopularButton;
