// PlatformButton.js

import React from "react";
import { IconButton, Toolbar, Typography } from "@mui/material";
import { Global } from "../../GlobalStyles";

const getIconButtonStyles = {
  width: "auto",
  padding: "4px 10px",
  margin: "3px 2px",
  borderColor: Global.greyBorder,
  borderWidth: "1px",
  borderRadius: "25px",
  borderStyle: "solid",
  "&:hover": {
    color: "white",
    backgroundColor: Global.greyBorder,
  },
};

const selectedIconButtonStyles = {
  backgroundColor: Global.redEnable,
  "&:hover": {
    color: "white",
    backgroundColor: Global.redHover,
  },
};

const typographyStyles = {
  fontFamily: "Pretendard",
  color: "white",
  fontSize: "12px",
};

const PlatformButton = ({ handlePlatformBtnClick, selectedPlatform, platformLabels }) => {
  //console.log(platformLabels);

  return (
    <Toolbar
      sx={{
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        maxWidth: "1125px",
        margin: "0px auto 0",
        backgroundColor: "#161823",
        // backgroundColor: "wheat",
        borderRadius: "10px",
        padding :"10px 10px"
      }}
    >
      {platformLabels.map((button, index) => (
        <IconButton
          key={index}
          size="small"
          onClick={() => handlePlatformBtnClick(index)}
          sx={{
            ...getIconButtonStyles,
            ...(selectedPlatform === index ? selectedIconButtonStyles : {}),
          }}
        >
          {button.iconSrc ? (
            <img
              src={button.iconSrc}
              style={{ width: "22px", height: "18px", borderRadius: "4px" }}
              alt={button.label}
            />
          ) : (
            <Typography variant="body2" sx={typographyStyles}>
              {button.label}
            </Typography>
          )}
        </IconButton>
      ))}
    </Toolbar>
  );
};

export default PlatformButton;
