import React from "react";

const LoadingSpinner = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "10vh", // 화면 전체 높이에 맞추려면 이 부분을 조절하세요
      }}
    >
      <img
        src="images/Loading6.gif"
        alt="Loading"
        style={{
          width: "50px", // 원하는 너비로 조절하세요
          height: "50px", // 원하는 높이로 조절하세요
        }}
      />
    </div>
  );
};

export default LoadingSpinner;
