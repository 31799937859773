import { createGlobalStyle, ThemeProvider } from "styled-components";
import { createTheme } from "@mui/material/styles";
import { grey } from "@mui/material/colors";

const DefaultBGColor = "#232736";
// const DefaultBGColor = grey[200];
const DefaultGrey = "#BCBCBC";

const Global = {
  redEnable: "#F83D3D",
  redHover: "#F83D3D",
  greyHover: "#6C757D",
  greyBorder: "#6C757D",
  greyDefault: DefaultGrey, // 기본 색상을 상수로 지정
  greyBG: DefaultBGColor,
  greyCardBG: "#171923",
};

const stSubPage = {
  border: "#393C4A",
  title: "#CCCCCC",
  summary: "#6C6F7A",
  red: "#DC3545",
  genreBadge: "#212529",
  genreBadgeBG: "#F8F9FA",
};

const GlobalStyle = createGlobalStyle`
  body {
    font-family: "Pretendard", sans-serif;
    background-color: ${DefaultBGColor};
    color:${DefaultGrey};//font color
  }
  /* 다른 전역 스타일 설정 */
`;

const MUITheme = createTheme({
  typography: {
    fontFamily: "'Pretendard', sans-serif",
  },
  palette: {
    primary: {
      main: DefaultGrey,
    },
    secondary: {
      main: grey[50],
    },
  },
});

const MaterialGlobalStyle = ({ children }) => (
  <ThemeProvider theme={MUITheme}>{children}</ThemeProvider>
);

export { GlobalStyle, MaterialGlobalStyle, MUITheme, Global, stSubPage };
