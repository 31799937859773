import React, { useState } from "react";
import { AppBar, BottomNavigation, BottomNavigationAction, Button } from "@mui/material";
import HouseIcon from "@mui/icons-material/House";
import TocIcon from "@mui/icons-material/Toc";
import SubjectIcon from "@mui/icons-material/Subject";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate } from "react-router-dom";
import EpisodeDialog from "../Dialog/EpisodeDialog";

const ChapterBottomNav = ({ episode, summary, onEpisodeClick }) => {
  // Home Navigate
  const navigate = useNavigate();
  const handleHomeIconClick = () => {
    navigate("/"); // "/" 경로로 이동
    window.location.reload();
  };

  // 목록 Navigate
  // data.Title를 미리 수정한 변수를 생성
  const modifiedTitle = summary.Title.replace("UP\t\t ", "");
  const handleSubjectIconClick = (title) => {
    navigate(`/subpage?title=${encodeURIComponent(title)}`);
  };


  // 이전화 Navigate
  // episodeTitle에서 "-" 앞의 숫자를 추출하여
  const episodeTitle = episode.EpisodeTitle;
  const episodeNumber =
    episodeTitle && episodeTitle.match(/(\d+) -/)
      ? parseInt(episodeTitle.match(/(\d+) -/)[1])
      : null;
  const prevEpisodeNumber = (episodeNumber - 1).toString(); // 1을 뺀 값을 생성
  // summary.SeriesInfo 배열에서 다음 에피소드 번호를 가진 요소를 찾음
  const prevEpisode = summary.SeriesInfo.find((item) =>
    item.EpisodeTitle.includes(prevEpisodeNumber)
  );
  const handleBackClick = () => {
    // console.log(summary);
    // console.log(episode);
    navigate("/content", { state: { summary, episode: prevEpisode } });
  };

  const [isModalOpen, setIsModalOpen]= useState(false);
  // "회차" 버튼을 클릭하면 Modal을 열도록 합니다.
  const handleChapterClick = (e) =>{
    e.stopPropagation(); // 이벤트 전파 중지
    onEpisodeClick(e); // 부모 컴포넌트로 이벤트 전달
    //console.log('handleChapterClick');
    setIsModalOpen(true);
  };

  const handleStopPropagation = (e) =>{
    e.stopPropagation(); // 이벤트 전파 중지
    onEpisodeClick(e); // 부모 컴포넌트로 이벤트 전달
  };

  return (
    <div>
      <AppBar position="fixed" sx={{ top: "auto", bottom: 0, height: "50px" }}>
        <BottomNavigation
          showLabels={false}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            height: "100%",
            backgroundColor: "#131417",
          }}
        >
          <BottomNavigationAction
            showLabel={true}
            label="홈"
            icon={<HouseIcon style={{ color: "white" }} />}
            sx={{ color: "white" }}
            onClick={handleHomeIconClick}
          />
          <BottomNavigationAction
            showLabel={true}
            label="목록"
            icon={<SubjectIcon style={{ color: "white" }} />}
            sx={{ color: "white" }}
            onClick={() => handleSubjectIconClick(modifiedTitle)}
          />
          <BottomNavigationAction
            showLabel={true}
            label="회차"
            icon={<TocIcon style={{ color: "white" }} />}
            sx={{ color: "white" }}
            onClick={handleChapterClick}
          />
          {prevEpisode && (
            <BottomNavigationAction
              showLabel={true}
              label="이전화"
              icon={<ArrowBackIosIcon fonsize="small" style={{ color: "white" }} />}
              sx={{ color: "white" }}
              onClick={() => handleBackClick()}
            />
          )}
        </BottomNavigation>
      </AppBar>
      {isModalOpen && (
        <EpisodeDialog
          summary={summary}
          episode={episode}
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onAlignClick={handleStopPropagation}
        />
      )}
    </div>
  );
};

export default ChapterBottomNav;
