// CardStyles.js
import { Global } from "../../../GlobalStyles";

// 공통 스타일 변수 정의
const cardWidth = "112px";
const cardHeight = "160px";
const marginLeft = "2px";
const marginTop = "2px";
const genreSize = "10px";

export const cardStyles = {
  cardStyle: {
    backgroundColor: Global.greyCardBG,
    width: cardWidth,
    height: cardHeight,
    marginBottom: "5px",
  },
  cardMediaStyle: {
    width: cardWidth,
    height: "93px",
  },
  cardContentStyle: {
    width: cardWidth,
    height: "64px",
  },

  PlatformImgStyle: {
    position: "absolute",
    top: marginTop,
    left: marginLeft,
    width: "20px",
    height: "20px",
    borderRadius: "3px",
  },
  getCreateAtStyle: (isSerial) => ({
    position: "absolute",
    top: marginTop,
    left: "24px",
    fontSize: "10px",
    fontWeight: "700",
    backgroundColor: isSerial === true ? "white" : Global.greyBorder,
    color: isSerial === true ? "red" : "white",
    padding: "4px 2px 2px",
    borderRadius: "4px",
  }),
  AgeLimitStyle: {
    position: "absolute",
    top: marginLeft,
    right: marginLeft,
    fontSize: "12px",
    fontWeight: "700",
    backgroundColor: "#dc3545",
    color: "white",
    padding: "4px 2px 2px",
    borderRadius: marginLeft,
  },
  GenreStyleContainer: {
    position: "absolute",
    bottom: marginTop,
    left: marginLeft,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  GenreStyle: {
    fontSize: genreSize,
    backgroundColor: "white",
    color: "black",
    padding: "1.5px 1.5px 1px",
    borderRadius: "4px",
    marginRight: marginLeft,
    fontWeight: "700",
    width: "auto",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  UpStyle: {
    fontSize: "8px",
    fontWeight: "bold",
    color: "#cc3030",
    position: "absolute",
    bottom: "46px",
    left: marginLeft,
    borderRadius: "2px",
    border: "1px solid red",
  },
  TitleStyle: {
    fontSize: "15px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    fontWeight: "500",
    color: "#ccc",
    position: "absolute",
    bottom: "42px",
  },
  LatestEpisodeStyle: {
    fontSize: "11px",
    color: "#a2a5b1",
    position: "absolute",
    bottom: "26px",
    left: marginLeft,
    borderRadius: "3px",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  avatarStyle: {
    position: "absolute",
    bottom: "8px",
    left: "1px",
    width: "14px",
    height: "14px",
    color: "#6c757d",
  },
  AuthorStyle: {
    fontSize: "10px",
    position: "absolute",
    bottom: "9px",
    left: "16px",
    color: "#6c757d",
    borderRadius: "3px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  deleteBtnStyle: {
    fontSize: "12px",
    color: "#dc3545",
    position: "absolute",
    right: "8px",
    bottom: "9px",
    cursor: "pointer",
  },
  deleteIconStyle: {
    color: "#dc3545",
    position: "absolute",
    right: "18px",
    bottom: "-3px",
  },
};
