// GenreButton.js

import React from "react";
import { Button, Toolbar } from "@mui/material";
import { Global } from "../../GlobalStyles";

const getGenreBtnStyle = (index, selectedGenre) => ({
  width: "auto",
  minWidth: "unset", // 최소 너비 제거
  height: "25px",
  margin: "3px",
  whiteSpace: "nowrap", // 텍스트가 한 줄로 유지되도록 설정
  fontFamily: "Pretendard, sans-serif",
  color: "white",
  fontSize: "13px",
  borderColor: Global.greyBorder,
  borderWidth: "1px",
  borderStyle: "solid",
  backgroundColor: selectedGenre === index ? Global.redEnable : Global.DefaultBGColor,
  "&:hover": {
    color: "white",
    backgroundColor: selectedGenre === index ? Global.redHover : Global.greyHover,
  },
});

const GenreButton = ({ handleGenreBtnClick, selectedGenre, genreLabels }) => {
  return (
    <Toolbar
      sx={{
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        maxWidth: "1170px",
        margin: "0px auto 0",
      }}
    >
      {genreLabels.map((label, index) => (
        <Button
          key={index}
          onClick={() => handleGenreBtnClick(index)}
          sx={getGenreBtnStyle(index, selectedGenre)}
        >
          {label}
        </Button>
      ))}
    </Toolbar>
  );
};

export default GenreButton;

// 추가할 버튼 이름들
