import React from "react";
import { Button, Toolbar } from "@mui/material";
import { stSubPage, Global, GlobalStyle, MaterialGlobalStyle } from "../../../GlobalStyles";
import BookmarkIcon from "@mui/icons-material/Bookmark";

const commonButtonStyles = {
  padding: "4px 8px",
  width: "50%",
  fontFamily: "Pretendard, sans-serif",
  fontSize: "17px",
};
const buttonContainerStyle = {
  fontSize: "14px",
  paddingBottom: "10px",
  display: "block",
  marginLeft: "-20px",
  marginRight: "-20px",
};

const bookMarkBtnSx = (hasBookmark) => ({
  ...commonButtonStyles,
  color: hasBookmark ? "white" : Global.greyHover,
  border: `1px solid ${Global.greyHover}`,
  "&:hover": {
    color: hasBookmark ? "white" : Global.greyHover,
    backgroundColor: hasBookmark ? Global.redEnable : "",
  },
  backgroundColor: hasBookmark ? stSubPage.red : "",
});

const buttonSx = {
  ...commonButtonStyles,
  color: Global.greyHover,
  border: `1px solid ${Global.greyHover}`,
  "&:hover": {
    color: "white",
    backgroundColor: Global.greyHover,
  },
};

const BookmarkAuthorButton = ({ windowSize, hasBookmark, handleBookmarkClick }) => {
  return (
    <div style={buttonContainerStyle}>
      <MaterialGlobalStyle>
        <GlobalStyle />
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Button sx={bookMarkBtnSx(hasBookmark)} onClick={handleBookmarkClick}>
            <BookmarkIcon fontSize="small" />
            {hasBookmark ? "북마크 삭제" : "북마크 추가"}
          </Button>
          <Button sx={{ ...buttonSx, marginLeft: "10px" }}>작가의 다른 작품</Button>
        </Toolbar>
      </MaterialGlobalStyle>
    </div>
  );
};

export default BookmarkAuthorButton;
