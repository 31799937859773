// DayButton.js

import React from "react";
import { Button, Toolbar } from "@mui/material";
import { Global } from "../../GlobalStyles";

const DaysButton = ({ handleDayBtnClick, selectedDay, dayLabels }) => {
  const getDayBtnStyle = (index) => ({
    width: "200px", // 폭을 줄임
    minWidth: "unset", // 최소 너비 제거
    fontFamily: "Pretendard, sans-serif",
    color: "white",
    fontSize: "15px",
    borderColor: Global.greyBorder,
    borderWidth: "1px",
    borderStyle: "solid",
    backgroundColor: selectedDay === index ? Global.redEnable : Global.greyBG,
    "&:hover": {
      color: "white",
      backgroundColor: selectedDay === index ? Global.redHover : Global.greyHover,
    },
    marginTop: "10px",
  });

  return (
    <Toolbar sx={{ display: "flex", justifyContent: "left", maxWidth: "1170px", margin: "0 auto" }}>
      {dayLabels.map((label, index) => (
        <Button key={index} onClick={() => handleDayBtnClick(index)} sx={getDayBtnStyle(index)}>
          {label}
        </Button>
      ))}
    </Toolbar>
  );
};

export default DaysButton;
