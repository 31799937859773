import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import ChapterHeaderNav from "../Navi/ChapterHeaderNav";
import ChapterCenterNav from "../Navi/ChapterCenterNav";
import ChapterBottomNav from "../Navi/ChapterBottomNav";
import ChapterTailNav from "../Navi/ChapterTailNav";
import ScrollIndicator from "../Indicator/ScrollIndicator";
import { useCookies } from "react-cookie"; // react-cookie에서 useCookies 가져오기
import LoadingSpinner from "../Spinner/LoadingSpinner";

const ContentPage = () => {
  const location = useLocation();
  const summary = location.state.summary;
  const episode = location.state.episode;
  const [cookies, setCookie] = useCookies(["historyTitle"]); // 'historyTitle' 쿠키 사용 선언
  const [isScrolling, setIsScrolling]= useState(false);

  // console.log("summary:", summary);
  // console.log("episode:", episode);

  useEffect(() => {
    if (!cookies.historyTitle) {
      const historyTitleCount = Object.keys(cookies).filter((name) =>
        name.startsWith("historyTitle")
      ).length;
      setCookie(`historyTitle${historyTitleCount}`, summary.Title, {
        expires: new Date("9999-01-01"),
        path: "/",
      });
    }
  }, [cookies, setCookie, summary.Title]);

  useEffect(() => {
    // 쿠키 정보를 출력합니다.
    console.log("Cookies:", cookies);
  }, [cookies]);

  const [isClicked, setIsClicked] = useState(true);
  // console.log("summary.Title" + summary.Title);

  const handleClick = () => {
    console.log("handleClick is called");
    setIsClicked(!isClicked);
  };

  const handleStopPropagation = (e) => {
    e.stopPropagation(); // 이벤트 전파 중지
  };

  useEffect(() => {
    // 스크롤 이동이 끝난 후에 화면을 렌더링
    if (isScrolling) {
      setIsScrolling(false);
    }
  }, [isScrolling]);

  // 스크롤을 맨 위로 이동 (렌더링될 때마다)
  useEffect(() => {
    setIsScrolling(true);
    window.scrollTo({ top: 0, behavior: "auto" });
  }, [location]);

  useEffect(() => {
    const handleWindowClick = () => {
      handleClick();
    };

    window.addEventListener("click", handleWindowClick);

    return () => {
      window.removeEventListener("click", handleWindowClick);
    };
  }, [isClicked]); // isClicked 상태가 변경될 때만 이펙트가 다시 실행됨

  if (isScrolling) {
    console.log("isScrolling:");
    return <LoadingSpinner />;
  }

  const renderImages = () => {
    const images = [];
    const startImgURL = episode.StartImgURL;
    const endImgURL = episode.EndImgURL;

    const startImgNumber = startImgURL.match(/(\d+)\.jpg/)[1];
    const endImgNumber = endImgURL.match(/(\d+)\.jpg/)[1];

    // console.log("startImgNumber:", startImgNumber);
    // console.log("endImgNumber:", endImgNumber);

    for (let i = parseInt(startImgNumber); i <= parseInt(endImgNumber); i++) {
      const imageURL = startImgURL.replace(startImgNumber, i.toString());
      images.push(
        <img
          key={i}
          src={imageURL}
          alt={`Image ${i}`}
          style={{
            maxWidth: "100%",
            display: "block",
            margin: "0 auto",
          }}
        />
      );
    }
    
    return images;
  };

  return (
    <div style={{ position: "relative", textAlign: "center", alignItems: "center" }}>
      <div style={{ position: "relative" }}>
        {renderImages()}
        {isClicked && (
          <>
            <ChapterHeaderNav episode={episode} summary={summary} />
            <ChapterCenterNav
              episode={episode}
              summary={summary}
              onArrowUpClick={handleStopPropagation}
              onArrowDownClick={handleStopPropagation}
            />
            <ChapterBottomNav
              episode={episode}
              summary={summary}
              onEpisodeClick={handleStopPropagation}
            />
          </>
        )}
      </div>
      {/* <ChapterTailNav episode={episode} summary={summary} /> */}
      <ScrollIndicator />
    </div>
  );
};

export default ContentPage;
