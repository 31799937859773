import React, { useState, useEffect } from "react";
import SeriesInfoCard from "../../Card/SeriesInfoCard";
import LoadingSpinner from "../../Spinner/LoadingSpinner";
import { useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import { platformImageMap } from "../../Card/ShortCutCard/PlatformMapGenreLabels";
import WebtoonSummaryCard from "./WebtoonSummaryCard";

const SubPage = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const title = searchParams.get("title");

  console.log("SubPage title:" + title);
  const [webtoonSummaries, setWebtoonSummaries] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [isReversed, setIsReversed] = useState(false);

  // useCookies 훅을 사용하여 쿠키를 가져옴
  const [cookies, setCookie] = useCookies(["bookmarkTitle"]);
  const bookmarkCookies = {};
  for (const cookieName in cookies) {
    if (cookieName.startsWith("bookmarkTitle")) {
      bookmarkCookies[cookieName] = cookies[cookieName];
    }
  }
  console.log("bookmarkCookies:", bookmarkCookies);

  // bookmarkCookies 객체에서 title과 같은 값이 있는지 확인
  const [hasBookmark, setHasBookMark] = useState(Object.values(bookmarkCookies).includes(title));
  console.log("hasBookmark:" + hasBookmark);

  const apiURL = `${process.env.REACT_APP_API_URL}webtoon_summaries?title=${title}`;
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(apiURL);
        const data = await response.json();
        setWebtoonSummaries(data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching webtoon summaries:", error);
        setIsLoading(false);
      }
    };

    fetchData();

    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [title]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (webtoonSummaries.length === 0) {
    return <h3>목록을 읽어오는데 오류가 발생했습니다.</h3>;
  }

  const handleSortClick = () => {
    setIsReversed(!isReversed);
  };

  const handleBookmarkClick = () => {
    console.log("북마크 추가 버튼이 클릭되었습니다.");
    if (hasBookmark === false) {
      // 북마크 추가
      console.log("북마크 추가 title:" + title);

      const keyVal = encodeURIComponent(`bookmarkTitle${title}`);
      setCookie(keyVal, title, {
        expires: new Date("2025-01-01"),
        path: "/",
      });

      console.log("북마크가 추가되었습니다.");
      console.log(cookies);
      setHasBookMark(true);
    } else {
      // 북마크 삭제
      console.log("북마크 삭제");
      const bookmarkToDelete = Object.keys(cookies).find(
        (name) => name.startsWith("bookmarkTitle") && cookies[name] === title
      );
      console.log(bookmarkToDelete);
      if (bookmarkToDelete) {
        setCookie(bookmarkToDelete, "", { path: "/", expires: new Date(0) });
        console.log("북마크가 삭제되었습니다.");
      }
      setHasBookMark(false);
    }
  };

  return (
    <div>
      {webtoonSummaries.map((summary) => (
        <section key={summary._id}>
          <WebtoonSummaryCard
            summary={summary}
            windowSize={windowSize}
            platformImageMap={platformImageMap}
            handleBookmarkClick={handleBookmarkClick}
            hasBookmark={hasBookmark}
          />
          <SeriesInfoCard
            summary={summary}
            curEpisode={undefined}
            isReversed={isReversed}
            showDate={true}
            handleSortClick={handleSortClick}
          />
        </section>
      ))}
    </div>
  );
};

export default SubPage;
